import dayjs from 'dayjs';
import { Fragment, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import config from "../config.js";
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import LoadingSpinner from './LoadingSpinner.js';

export default function AddPaymentModal(props) {

    const refund = props.refund;
    const parentPayment = props.parentPayment;

    const paymentTypes = (props.parentPayment?.type=='Credit Card' ? ['Credit Card', 'Direct Bill', 'Cash'] : (props.stripePaymentMethodIdKnown ? ['Credit Card', 'Direct Bill', 'Cash'] : ['Direct Bill', 'Cash'])).map(pt=>{return {value: pt, label: pt}});

    const [paymentDate, setPaymentDate] = useState(dayjs().toDate());
    const [paymentAmount, setPaymentAmount] = useState(null);
    const [paymentType, setPaymentType] = useState(paymentTypes[0].value);

    const [errorMsg, setErrorMsg] = useState(null);
    const [saving, setSaving] = useState(false);

    const maxRefundable = refund ? parentPayment.amount - (parentPayment.refunds ? parentPayment.refunds.reduce((accum, payment) => accum - payment.amount, 0) : 0) : Number.MAX_VALUE;

    const save = (e) => {
        e.preventDefault();
        const form = document.querySelector('form.add-payment-form');
        form.classList.add('was-validated');

        if (form.checkValidity()===true) {
            setSaving(true);

            if (!refund && paymentType=='Credit Card') return takeCardPayment();

            fetch(`${config.server_base_url}/api/payments`, 
            {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                bookingId: props.bookingId,
                date: dayjs(paymentDate).format('YYYY-MM-DD'), 
                amount: refund ? -paymentAmount : paymentAmount,
                type: paymentType,
                parentPaymentId: parentPayment?.id
            })
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else setErrorMsg(data.reason) })
            .then(() => setSaving(false))
            .catch((err) => setSaving(false));
        }
    }

    const takeCardPayment = () => {
        setSaving(true);
        fetch(`${config.server_base_url}/api/bookings/${props.bookingId}/takePayment`, 
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                amount: paymentAmount
              })
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else setErrorMsg(data.reason) })
            .then(() => setSaving(false))
            .catch((err) => setSaving(false));
      }

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Add {refund ? 'Refund' : 'Payment'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={save} className='add-payment-form'>
                        {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                        {refund ? <p><em>You can refund up to €{maxRefundable}.</em></p> : <Fragment />}
                        <div className='form-row multiple-fields-row'>
                            <div>
                                <label>Date:</label>
                                <DatePicker selected={paymentDate} onChange={(date) => setPaymentDate(date)} dateFormat="dd MMM, yyyy" calendarStartDay={1} /> 
                            </div>
                            <div>
                                <label>Amount:</label>
                                <Form.Control required step=".01" min='0' max={refund ? maxRefundable : Number.MAX_VALUE} type="number" onChange={(e)=>setPaymentAmount(e.target.value)} className='form-input' />
                            </div>
                            <div>
                                <label>Type:</label>
                                <Select defaultValue={paymentTypes[0]} onChange={(paymentType)=>setPaymentType(paymentType.value)} options={paymentTypes} />
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                    <Button variant="primary" onClick={save} disabled={saving}>{saving ?<LoadingSpinner /> : 'Save'}</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}